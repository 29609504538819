import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { SetSidebarCollapsedStatePreferenceMutation } from '@/components/Settings/Personal/Profile/queries.graphql';
import { useCurrentUser } from '@/context/UserContext';
import { logout } from '@/utilities/jwtLocalstorage';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const { currentUser, handleUserUpdate } = useCurrentUser();

  // bail if currentUser is somehow null
  if (currentUser === null) {
    logout();
  }

  const [leftNavExpanded, setLeftNavExpanded] = useState(currentUser.sidebar_collapsed_state);
  const [leftNavDisabled, setLeftNavDisabled] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [mutateSidebarCollapsedStatePreference] = useMutation(SetSidebarCollapsedStatePreferenceMutation);
  const { hostname } = new URL(window.location.href);
  const [appName] = useState(() => {
    let site = 'Paminga';
    if (hostname.includes('.net-results.com')) {
      site = 'Goldilocks';
    }
    return site;
  });
  const [companyName] = useState(() => {
    let site = 'Paminga';
    if (hostname.includes('.net-results.com')) {
      site = 'Net-Results';
    }
    return site;
  });
  const leftNavExpandedWidth = 240;
  const leftNavCollapsedWidth = 69;
  const currentLeftNavWidth = leftNavExpanded ? leftNavExpandedWidth : leftNavCollapsedWidth;
  const handleLeftNavToggle = useCallback(() => {
    mutateSidebarCollapsedStatePreference({
      variables: { user_id: currentUser.id, sidebar_collapsed_state: !currentUser.sidebar_collapsed_state },
    });
    handleUserUpdate('sidebar_collapsed_state', !leftNavExpanded);
    setLeftNavExpanded(!leftNavExpanded);
  }, [leftNavExpanded, handleUserUpdate, currentUser, mutateSidebarCollapsedStatePreference]);

  // memoize the full context value
  const contextValue = useMemo(
    () => ({
      appName,
      companyName,
      headerHeight,
      currentLeftNavWidth,
      handleLeftNavToggle,
      leftNavDisabled,
      leftNavExpanded,
      setHeaderHeight,
      setLeftNavDisabled,
    }),
    [
      appName,
      companyName,
      headerHeight,
      currentLeftNavWidth,
      handleLeftNavToggle,
      leftNavDisabled,
      leftNavExpanded,
      setHeaderHeight,
      setLeftNavDisabled,
    ]
  );

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error(`useAppContext must be used within a AppProvider`);
  }
  return context;
};

export { AppProvider, useAppContext };

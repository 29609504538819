import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Help } from '@mui/icons-material';

const KnowledgeBaseArticleLink = ({
  renderAsButton,
  tooltipTitle,
  url,
}: {
  renderAsButton?: boolean;
  tooltipTitle?: string;
  url: string;
}) => {
  if (renderAsButton) {
    return (
      <Tooltip title={tooltipTitle} enterDelay={700} arrow>
        <IconButton size="large" edge="end">
          <Help
            sx={{ fontSize: 'inherit', color: 'brand.techBlue', cursor: 'pointer' }}
            onClick={() => {
              window.open(url, '_blank')!.focus();
            }}
          />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tooltipTitle} enterDelay={700} arrow>
      <Help
        sx={{ fontSize: 28, color: 'brand.techBlue', cursor: 'pointer' }}
        onClick={() => {
          window.open(url, '_blank')!.focus();
        }}
      />
    </Tooltip>
  );
};

KnowledgeBaseArticleLink.defaultProps = {
  renderAsButton: false,
  tooltipTitle: 'Open the Knowledge Base Article in a New Tab',
};

export default KnowledgeBaseArticleLink;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Link, Stack, Divider } from '@mui/material';
import NoResultsFound from '@/components/NoResultsFound';

const FeatureReleaseBlogPostsFeed = ({ dialogOpen }) => {
  const [feedItems, setFeedItems] = useState([]);

  useEffect(() => {
    const fetchFeed = async () => {
      const response = await fetch('https://paminga.com/blog/feed.json');
      const data = await response.json();
      setFeedItems(data.items);
    };

    if (dialogOpen) {
      fetchFeed();
    }
  }, [dialogOpen]);

  if (feedItems.length === 0) {
    return (
      <NoResultsFound
        fillEntireContainer
        message={<Typography variant="h5">We looked, but were unable to find any blog posts. 🤷‍♀️</Typography>}
      />
    );
  }

  return (
    <Stack spacing={2} padding={2} divider={<Divider />}>
      {feedItems.map((item) => (
        <Box key={item.id} padding={1} sx={{ position: 'relative' }}>
          <Box sx={{ position: 'absolute', top: 16, right: 10 }}>
            {new Date(item.date_modified).toLocaleDateString()}
          </Box>
          <Link
            variant="h2"
            href={item.url}
            target="_blank"
            rel="noreferrer"
            sx={{
              fontWeight: 700,
              textDecoration: 'none',
              color: 'palette.brand.techBlue',
              ':visited': {
                color: 'palette.brand.techBlue',
              },
            }}
          >
            {item.title}
          </Link>
          <Box sx={{ fontSize: '16px', marginTop: 1 }} dangerouslySetInnerHTML={{ __html: item.content_html }} />
        </Box>
      ))}
    </Stack>
  );
};

FeatureReleaseBlogPostsFeed.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
};

export default FeatureReleaseBlogPostsFeed;

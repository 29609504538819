import React from 'react';
import { LicenseInfo } from '@mui/x-license';
import { useAuth } from '@/context/AuthContext';
import AuthenticatedApp from 'goldilocks/AuthenticatedApp';
import UnauthenticatedApp from 'goldilocks/UnauthenticatedApp';
import 'goldilocks/App.css';

// MUI-X License Key
LicenseInfo.setLicenseKey(
  '52cf4afc5615f247c277d995e1170846Tz0xMDE2OTYsRT0xNzYzMDY0MjU2MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y'
);

const App = () => {
  const { data } = useAuth();
  return data ? <AuthenticatedApp /> : <UnauthenticatedApp />;
};

export default App;

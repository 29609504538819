import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select, Stack, Toolbar, FormControl, InputLabel, Pagination as MuiPagination } from '@mui/material';
import formatters from '@/utilities/Format';

const TablePagination = (props) => {
  return (
    <Stack alignItems="center" mt="-1px">
      <Toolbar>
        <Stack direction="column" spacing={0} alignItems="center" pt={2}>
          <Stack
            direction="row"
            spacing={0}
            p={1}
            sx={{ backgroundColor: (theme) => theme.palette.background.offset, borderRadius: '7px' }}
          >
            <MuiPagination
              count={Math.ceil(props.total / props.limit)}
              page={props.pageIndex + 1}
              boundaryCount={2}
              shape="rounded"
              size="medium"
              variant="text"
              showFirstButton
              showLastButton
              onChange={(e, page) => {
                props.pageChange(e, page - 1);
              }}
            />
          </Stack>
          <Stack direction="row" spacing={0}>
            {`Items ${formatters.formatNumber(props.from)} - ${formatters.formatNumber(
              props.to
            )} of ${formatters.formatNumber(props.total)}`}
          </Stack>
          <Stack direction="row" spacing={1} alignItems="baseline">
            <FormControl variant="outlined" size="small" fullWidth sx={{ minWidth: '100px', mt: 1 }}>
              <InputLabel>Rows per page</InputLabel>
              <Select
                label="Rows per page"
                value={props.limit}
                onChange={props.handleChangeRowsPerPage}
                sx={{ textAlign: 'center' }}
                size="small"
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </Toolbar>
    </Stack>
  );
};

TablePagination.defaultProps = {
  from: 0,
  to: 0,
};

TablePagination.propTypes = {
  pageChange: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  from: PropTypes.number,
  to: PropTypes.number,
  pageIndex: PropTypes.number.isRequired,
};

export default TablePagination;

import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { Cancel, OpenInNew } from '@mui/icons-material';
import NrButton from '@/components/NrMaterialOverrides/NrButton/index';

const DocsDialogTitle = ({ children, onClose, title, additionalTitle, loading, childrenPosition, url, variant }) => {
  const openInNewTab = () => {
    window.open(url, '_blank');
    onClose();
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      py={1}
      px={2}
      sx={{ backgroundColor: (theme) => theme.palette.brand.paminga.darkBlue }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant={variant} sx={{ my: 0, color: '#f2f2f2', fontWeight: 500 }}>
          You are viewing {title}.
        </Typography>
        {childrenPosition === 'LEFT' ? children : null}
      </Stack>

      {childrenPosition === 'MIDDLE' ? children : null}

      <Stack direction="row" alignItems="center" spacing={1}>
        {childrenPosition === 'RIGHT' ? children : null}
        <NrButton
          variant="secondary"
          onClick={openInNewTab}
          endIcon={<OpenInNew />}
          sx={{
            backgroundColor: 'buttons.primary.backgroundHoverColor',
            ':hover': {
              backgroundColor: '#3b6fb6',
            },
          }}
        >
          Open Docs in New Tab
        </NrButton>
        <NrButton
          variant="secondary"
          onClick={onClose}
          endIcon={<Cancel />}
          sx={{
            backgroundColor: 'buttons.primary.backgroundHoverColor',
            ':hover': {
              backgroundColor: '#3b6fb6',
            },
          }}
        >
          Return to Paminga
        </NrButton>
      </Stack>
    </Stack>
  );
};

DocsDialogTitle.defaultProps = {
  additionalTitle: null,
  children: null,
  childrenPosition: 'MIDDLE',
  loading: false,
  onClose: null,
  title: null,
  variant: 'body1',
};

DocsDialogTitle.propTypes = {
  additionalTitle: PropTypes.string,
  children: PropTypes.node,
  childrenPosition: PropTypes.oneOf(['LEFT', 'MIDDLE', 'RIGHT']),
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

export default DocsDialogTitle;
